import Links from './Links'
import Brand from './Brand'
import Copyright from './Copyright'

export default function Footer({ isCompressed = true }) {
  return (
    <footer id="footer" className="relative z-10">
      {!isCompressed && <Links />}
      <Brand isCompressed={isCompressed} />
      <Copyright />
    </footer>
  )
}