import FigmaFriends from './FigmaFriends'

const links = [
  {
    title: 'Platform',
    items: [
      { title: 'How it works', href: '/how-it-works' },
      { title: 'About us', href: '/about-us' },
      { title: 'Our work', href: '/work' },
      { title: 'Pricing', href: '/pricing' },
      { title: 'Blog', href: '/blog' },
    ],
  },
  {
    title: 'Links',
    items: [
      { title: 'Services', href: 'https://designproject.io/verticals/services', isExternal: true },
      { title: 'Ventures', href: 'https://tdp.vc', isExternal: true },
      { title: 'India', href: '/india', isExternal: true },
    ]
  },
  {
    title: 'Company',
    items: [
      { title: 'Join us', href: 'https://designproject.io/work-with-us?utm_source=website&utm_medium=footer&utm_content=joinus', isExternal: true, isHiring: true },
    ]
  }
]

export default function Links() {
  const router = useRouter()

  return (
    <div className="bg-jungle-700 pt-16 md:pt-[120px] pb-8 md:pb-20 border-t border-[#252323]">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-2 lg:grid-cols-[800px_100px] justify-between mb-16">
          <div>
            <ul className="flex flex-col md:flex-row gap-16 md:gap-28 lg:gap-52">
              {links.map((link, index) => (
                <li key={index}>
                  <h3 className="text-secondary-menthol text-xl uppercase border-b border-secondary-jungle pb-5 mb-6">
                    {link.title}
                  </h3>

                  <ul className="flex flex-col gap-6">
                    {link.items?.map((item, i) => (
                      <li key={i}>
                        <a
                          href={item.href}
                          target={item.isExternal ? '_blank' : '_self'}
                          className={`${router.asPath.includes(item.href) ? 'text-secondary-menthol' : 'text-neutral-100'} hover:text-neutral-50 inline-flex items-center gap-1 pb-0.5 border-b border-transparent hover:border-neutral-50 transition duration-150 ease-in-out relative`}
                        >
                          {item.title}
                          {item.isExternal && (
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M4 12L12 4M12 4H6.66667M12 4V9.33333" stroke="#F8F8F8" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                          )}
                          {item.isHiring && (
                            <span className="font-text text-secondary-menthol text-[10px] uppercase px-2 py-1 bg-secondary-jungle rounded absolute left-[calc(100%_-_-8px)] whitespace-nowrap">
                              We’re hiring
                            </span>
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex justify-end">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none">
              <g clipPath="url(#clip0_1114_229)">
                <path d="M31.9746 64C49.6336 64 63.9491 49.6731 63.9491 32C63.9491 14.3269 49.6336 0 31.9746 0C14.3155 0 0 14.3269 0 32C0 49.6731 14.3155 64 31.9746 64Z" fill="#FEFEFE"/>
                <path d="M36.7851 26.0738V21.6166H32.894V27.8829C31.969 26.7169 30.6305 26.0983 28.974 26.0983C25.7144 26.0983 23.402 28.6773 23.402 32.5737C23.402 36.4701 25.7389 39.0491 28.9962 39.0491C29.8234 39.0491 30.5705 38.8867 31.2264 38.5752C31.8823 38.2636 32.4426 37.8075 32.8918 37.2222C32.8918 38.0166 33.5343 38.6597 34.3237 38.6597H36.7828V34.2026C36.7828 32.843 36.5294 31.6302 36.1336 30.8113C35.9335 30.3996 35.9335 29.8789 36.1336 29.465C36.5294 28.6462 36.7828 27.4334 36.7828 26.0738H36.7851ZM30.2658 35.7602C28.5627 35.7602 27.4176 34.4963 27.4176 32.5715C27.4176 30.6466 28.5627 29.3827 30.2658 29.3827C31.969 29.3827 33.1097 30.6466 33.1097 32.5715C33.1097 34.4963 31.9668 35.7602 30.2658 35.7602Z" fill="#252323"/>
                <path d="M46.4245 26.1028C45.5351 26.1028 44.7458 26.2987 44.0632 26.6569C43.3851 27.0152 42.8136 27.5359 42.36 28.1946C42.36 27.2555 41.5996 26.49 40.6569 26.49H38.4667V30.9472C38.4667 32.3068 38.7202 33.5196 39.116 34.3384C39.3161 34.7501 39.3161 35.2708 39.116 35.6847C38.7202 36.5036 38.4667 37.7163 38.4667 39.076V43.5331H42.3578V36.6193C43.2605 37.9812 44.668 39.0804 46.4468 39.0804C49.7064 39.0804 52.0655 36.4747 52.0655 32.5783C52.0655 28.6819 49.6841 26.1028 46.4245 26.1028ZM45.2083 35.7937C43.5051 35.7937 42.36 34.5031 42.36 32.5783C42.36 30.6534 43.5051 29.3895 45.2083 29.3895C46.9115 29.3895 48.0521 30.6534 48.0521 32.5783C48.0521 34.5031 46.9092 35.7937 45.2083 35.7937Z" fill="#252323"/>
                <path d="M20.5179 35.5155C19.3995 35.5155 19.0481 34.7166 19.0481 33.9623V29.7632H22.0587C22.4278 28.4659 23.0571 27.3511 23.8931 26.4766H19.0481V23.092H16.8714C15.922 23.092 15.1549 23.8597 15.1549 24.8099V26.4766H12.6468V29.7632H15.1549V34.4118C15.1549 36.2142 15.5907 39.038 19.6796 39.038C19.8219 39.038 19.9598 39.0336 20.0954 39.0269C20.231 39.0202 20.36 39.0091 20.4867 38.9957C21.7274 38.8823 22.728 38.5084 23.4439 38.0789C22.8058 37.2623 22.3211 36.2876 22.0231 35.1928C21.5362 35.3731 21.0026 35.5132 20.5179 35.5132V35.5155Z" fill="#252323"/>
              </g>
              <defs>
                <clipPath id="clip0_1114_229">
                  <rect width="63.9491" height="64" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <div>
          <FigmaFriends />
        </div>
      </div>
    </div>
  )
}