export default function Copyright() {
  return (
    <div className="relative bg-secondary-jungle py-8 md:py-10 border-t border-jungle-700">
      <div className="container mx-auto px-6">

        <div className="flex flex-col md:flex-row items-center justify-between">
          <p className="text-neutral-200 text-center md:text-left mb-6 md:mb-0">
            <span className="font-semibold">© 2025 - Eberhardt Design LLC.</span><br className="md:hidden"/> Miami, FL 33179, United States
          </p>

          <ul className="flex items-center gap-6 lg:gap-14">
            <li>
              <a href="https://designproject.io/terms" target="_blank" className="text-neutral-200 flex items-center gap-1">
                Terms of Service
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M4 12.5L12 4.5M12 4.5H6.66667M12 4.5V9.83333" stroke="#E2E2E2" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </li>

            <li>
              <a href="https://designproject.io/privacy" target="_blank" className="text-neutral-200 flex items-center gap-1">
                Privacy Policy
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
                  <path d="M4 12.5L12 4.5M12 4.5H6.66667M12 4.5V9.83333" stroke="#E2E2E2" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
  )
}