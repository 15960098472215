
export default function FigmaFriends() {
  return (
    <div className="py-4 px-6 border border-neutral-400 rounded-full inline-flex items-center gap-4">
      <div className="flex items-center gap-2">
        <div>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#F8F8F8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.7685 12.6329C27.7685 13.9131 26.7271 14.9509 25.4425 14.9509H23.0743V10.3149H25.4425C26.7271 10.3149 27.7685 11.3527 27.7685 12.6329ZM25.4425 14.1923C26.3067 14.1923 27.0072 13.4942 27.0072 12.6329C27.0072 11.7717 26.3067 11.0736 25.4425 11.0736H23.8355V14.1923H25.4425Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1414 12.6329C19.1414 13.9131 20.1827 14.9509 21.4673 14.9509H23.8356V10.3149H21.4673C20.1827 10.3149 19.1414 11.3527 19.1414 12.6329ZM21.4673 14.1923C20.6031 14.1923 19.9026 13.4942 19.9026 12.6329C19.9026 11.7717 20.6031 11.0736 21.4673 11.0736H23.0743V14.1923H21.4673Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1414 16.5102C19.1414 17.7904 20.1827 18.8282 21.4673 18.8282H23.8356V14.1923L21.4673 14.1923C20.1827 14.1923 19.1414 15.2301 19.1414 16.5102ZM21.4673 18.0696C20.6031 18.0696 19.9026 17.3715 19.9026 16.5102C19.9026 15.649 20.6031 14.9509 21.4673 14.9509L23.0743 14.9509V18.0696H21.4673Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1414 20.3876C19.1414 21.6704 20.1959 22.7056 21.4778 22.7056C22.7714 22.7056 23.8356 21.661 23.8356 20.3665V18.0696L21.4673 18.0696C20.1827 18.0696 19.1414 19.1074 19.1414 20.3876ZM21.4778 21.947C20.611 21.947 19.9026 21.2461 19.9026 20.3876C19.9026 19.5264 20.6031 18.8282 21.4673 18.8282L23.0743 18.8282V20.3665C23.0743 21.2367 22.3564 21.947 21.4778 21.947Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0739 16.5102C23.0739 17.7904 24.1153 18.8282 25.3999 18.8282H25.4421C26.7267 18.8282 27.7681 17.7904 27.7681 16.5102C27.7681 15.2301 26.7267 14.1923 25.4421 14.1923H25.3999C24.1153 14.1923 23.0739 15.2301 23.0739 16.5102ZM25.3999 18.0696C24.5357 18.0696 23.8351 17.3715 23.8351 16.5102C23.8351 15.649 24.5357 14.9509 25.3999 14.9509H25.4421C26.3063 14.9509 27.0069 15.649 27.0069 16.5102C27.0069 17.3715 26.3063 18.0696 25.4421 18.0696H25.3999Z" fill="#252323"/>
            <mask id="mask0_1114_883" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="5" y="10" width="13" height="12">
              <path d="M11.4055 21.9143L10.51 21.1117C7.32919 18.2722 5.22925 16.3934 5.22925 14.1012C5.22925 12.2224 6.72391 10.7571 8.62621 10.7571C9.70088 10.7571 10.7323 11.5834 11.4055 12.3556C12.0788 11.5834 13.1102 10.7571 14.1849 10.7571C16.0872 10.7571 17.5818 12.2224 17.5818 14.1012C17.5818 16.3934 15.4819 18.2722 12.3011 21.1117L11.4055 21.9143Z" fill="#236DF6"/>
            </mask>
            <g mask="url(#mask0_1114_883)">
              <rect x="0.0140086" y="0.0139397" width="14.9125" height="10.7343" rx="0.0975793" transform="matrix(1 0 0.00492779 0.999988 3.93829 10.7603)" fill="#FEFEFE" stroke="#F5F5F5" strokeWidth="0.0278798"/>
              <mask id="mask1_1114_883" style={{ maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="3" y="10" width="16" height="12">
                <rect x="0.0140086" y="0.0139397" width="14.9125" height="10.7343" rx="0.0975793" transform="matrix(1 0 0.00492779 0.999988 3.93829 10.7603)" fill="white" stroke="white" strokeWidth="0.0278798"/>
              </mask>
              <g mask="url(#mask1_1114_883)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.95603 14.3476H18.8964L18.8787 10.7603H3.93835L3.95603 14.3476Z" fill="#88BBE8"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M3.99143 21.5222H18.9318L18.9141 17.9348H3.97375L3.99143 21.5222Z" fill="#88BBE8"/>
                <path d="M11.4405 17.2362C12.0403 17.2362 12.5239 16.7459 12.5209 16.1414C12.5179 15.5369 12.0295 15.0466 11.4297 15.0466C10.83 15.0466 10.3464 15.5369 10.3494 16.1414C10.3524 16.7459 10.8407 17.2362 11.4405 17.2362Z" fill="#F4B32E" stroke="#DB7A2C" strokeWidth="0.0371731"/>
              </g>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4056 22.9309L10.0058 21.6765L9.98325 21.6563C8.41741 20.2585 7.05645 19.0436 6.09434 17.8849C5.11968 16.711 4.47217 15.5001 4.47217 14.1012C4.47217 11.7922 6.31794 10 8.62622 10C9.35581 10 10.0235 10.2785 10.5729 10.6281C10.8741 10.8197 11.1536 11.0414 11.4056 11.2723C11.6575 11.0414 11.937 10.8197 12.2382 10.6281C12.7876 10.2785 13.4553 10 14.1849 10C16.4932 10 18.3389 11.7922 18.3389 14.1012C18.3389 15.5001 17.6914 16.711 16.7168 17.8849C15.7547 19.0436 14.3937 20.2585 12.8279 21.6563L12.8064 21.6755L11.4056 22.9309ZM12.3011 21.1117C15.4819 18.2722 17.5818 16.3934 17.5818 14.1012C17.5818 12.2224 16.0872 10.7571 14.1849 10.7571C13.3632 10.7571 12.5668 11.2402 11.9364 11.8128C11.7423 11.9891 11.564 12.1739 11.4056 12.3556C11.2471 12.1739 11.0688 11.9891 10.8747 11.8128C10.2444 11.2402 9.44793 10.7571 8.62622 10.7571C6.72392 10.7571 5.22926 12.2224 5.22926 14.1012C5.22926 16.3934 7.3292 18.2722 10.51 21.1117L11.4056 21.9143L12.3011 21.1117Z" fill="#252323"/>
            <path d="M11.9214 11.8919C11.9214 11.6346 11.7129 11.4261 11.4556 11.4261C11.1984 11.4261 10.9899 11.6346 10.9899 11.8919C10.9899 12.1491 11.1984 12.3577 11.4556 12.3577C11.7129 12.3577 11.9214 12.1491 11.9214 11.8919Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4404 11.8917C12.4404 11.3478 11.9995 10.9069 11.4556 10.9069C10.9117 10.9069 10.4708 11.3478 10.4708 11.8917C10.4708 12.4356 10.9117 12.8765 11.4556 12.8765C11.9995 12.8765 12.4404 12.4356 12.4404 11.8917ZM11.4556 11.4259C11.7128 11.4259 11.9214 11.6345 11.9214 11.8917C11.9214 12.1489 11.7128 12.3575 11.4556 12.3575C11.1983 12.3575 10.9898 12.1489 10.9898 11.8917C10.9898 11.6345 11.1983 11.4259 11.4556 11.4259Z" fill="#252323"/>
            <path d="M18.3402 14.3142C18.3402 14.0292 18.1092 13.7983 17.8243 13.7983C17.5393 13.7983 17.3083 14.0292 17.3083 14.3142C17.3083 14.5991 17.5393 14.8301 17.8243 14.8301C18.1092 14.8301 18.3402 14.5991 18.3402 14.3142Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.915 14.3143C18.915 13.7119 18.4267 13.2235 17.8243 13.2235C17.2219 13.2235 16.7335 13.7119 16.7335 14.3143C16.7335 14.9167 17.2219 15.405 17.8243 15.405C18.4267 15.405 18.915 14.9167 18.915 14.3143ZM17.8243 13.7984C18.1092 13.7984 18.3402 14.0294 18.3402 14.3143C18.3402 14.5992 18.1092 14.8302 17.8243 14.8302C17.5393 14.8302 17.3084 14.5992 17.3084 14.3143C17.3084 14.0294 17.5393 13.7984 17.8243 13.7984Z" fill="#252323"/>
            <path d="M5.38447 14.3142C5.38447 14.0292 5.15349 13.7983 4.86857 13.7983C4.58364 13.7983 4.35266 14.0292 4.35266 14.3142C4.35266 14.5991 4.58364 14.8301 4.86857 14.8301C5.15349 14.8301 5.38447 14.5991 5.38447 14.3142Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.95932 14.3143C5.95932 13.7119 5.47098 13.2235 4.86858 13.2235C4.26617 13.2235 3.77783 13.7119 3.77783 14.3143C3.77783 14.9167 4.26617 15.405 4.86858 15.405C5.47098 15.405 5.95932 14.9167 5.95932 14.3143ZM4.86858 13.7984C5.1535 13.7984 5.38448 14.0294 5.38448 14.3143C5.38448 14.5992 5.1535 14.8302 4.86858 14.8302C4.58365 14.8302 4.35267 14.5992 4.35267 14.3143C4.35267 14.0294 4.58365 13.7984 4.86858 13.7984Z" fill="#252323"/>
            <path d="M11.8296 22.1634C11.8296 21.8986 11.6149 21.6838 11.35 21.6838C11.0851 21.6838 10.8704 21.8986 10.8704 22.1634C10.8704 22.4283 11.0851 22.6431 11.35 22.6431C11.6149 22.6431 11.8296 22.4283 11.8296 22.1634Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.364 22.1632C12.364 21.6032 11.91 21.1492 11.35 21.1492C10.7899 21.1492 10.3359 21.6032 10.3359 22.1632C10.3359 22.7232 10.7899 23.1772 11.35 23.1772C11.91 23.1772 12.364 22.7232 12.364 22.1632ZM11.35 21.6836C11.6149 21.6836 11.8296 21.8983 11.8296 22.1632C11.8296 22.4281 11.6149 22.6428 11.35 22.6428C11.0851 22.6428 10.8703 22.4281 10.8703 22.1632C10.8703 21.8983 11.0851 21.6836 11.35 21.6836Z" fill="#252323"/>
          </svg>
        </div>

        <div>
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
            <circle cx="16" cy="16" r="16" fill="#F8F8F8"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M27.7683 12.6329C27.7683 13.9131 26.727 14.9509 25.4424 14.9509H23.0741V10.3149H25.4424C26.727 10.3149 27.7683 11.3527 27.7683 12.6329ZM25.4424 14.1923C26.3066 14.1923 27.0071 13.4942 27.0071 12.6329C27.0071 11.7717 26.3066 11.0736 25.4424 11.0736H23.8354V14.1923H25.4424Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1412 12.6329C19.1412 13.9131 20.1826 14.9509 21.4672 14.9509H23.8354V10.3149H21.4672C20.1826 10.3149 19.1412 11.3527 19.1412 12.6329ZM21.4672 14.1923C20.603 14.1923 19.9025 13.4942 19.9025 12.6329C19.9025 11.7717 20.603 11.0736 21.4672 11.0736H23.0742V14.1923H21.4672Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1412 16.5102C19.1412 17.7904 20.1826 18.8282 21.4672 18.8282H23.8354V14.1923L21.4672 14.1923C20.1826 14.1923 19.1412 15.2301 19.1412 16.5102ZM21.4672 18.0696C20.603 18.0696 19.9025 17.3715 19.9025 16.5102C19.9025 15.649 20.603 14.9509 21.4672 14.9509L23.0742 14.9509V18.0696H21.4672Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.1412 20.3876C19.1412 21.6704 20.1958 22.7056 21.4777 22.7056C22.7713 22.7056 23.8354 21.661 23.8354 20.3665V18.0696L21.4672 18.0696C20.1826 18.0696 19.1412 19.1074 19.1412 20.3876ZM21.4777 21.947C20.6109 21.947 19.9025 21.2461 19.9025 20.3876C19.9025 19.5264 20.603 18.8282 21.4672 18.8282L23.0742 18.8282V20.3665C23.0742 21.2367 22.3562 21.947 21.4777 21.947Z" fill="#252323"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M23.0738 16.5102C23.0738 17.7904 24.1151 18.8282 25.3997 18.8282H25.442C26.7266 18.8282 27.768 17.7904 27.768 16.5102C27.768 15.2301 26.7266 14.1923 25.442 14.1923H25.3997C24.1151 14.1923 23.0738 15.2301 23.0738 16.5102ZM25.3997 18.0696C24.5356 18.0696 23.835 17.3715 23.835 16.5102C23.835 15.649 24.5356 14.9509 25.3997 14.9509H25.442C26.3062 14.9509 27.0068 15.649 27.0068 16.5102C27.0068 17.3715 26.3062 18.0696 25.442 18.0696H25.3997Z" fill="#252323"/>
            <mask id="mask0_1114_907" style={{ maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="5" y="10" width="13" height="12">
              <path d="M11.4054 21.9143L10.5099 21.1117C7.32907 18.2722 5.22913 16.3934 5.22913 14.1012C5.22913 12.2224 6.72379 10.7571 8.62609 10.7571C9.70076 10.7571 10.7322 11.5834 11.4054 12.3556C12.0786 11.5834 13.1101 10.7571 14.1848 10.7571C16.087 10.7571 17.5817 12.2224 17.5817 14.1012C17.5817 16.3934 15.4818 18.2722 12.301 21.1117L11.4054 21.9143Z" fill="#236DF6"/>
            </mask>
            <g mask="url(#mask0_1114_907)">
              <rect x="0.0140086" y="0.0139397" width="14.9125" height="10.7343" rx="0.0975793" transform="matrix(1 0 0.00492779 0.999988 3.93816 10.7603)" fill="#FFD47B" stroke="#FFD47B" strokeWidth="0.0278798"/>
              <mask id="mask1_1114_907" style={{ maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="3" y="10" width="16" height="12">
                <rect x="0.0140086" y="0.0139397" width="14.9125" height="10.7343" rx="0.0975793" transform="matrix(1 0 0.00492779 0.999988 3.93816 10.7603)" fill="white" stroke="white" strokeWidth="0.0278798"/>
              </mask>
              <g mask="url(#mask1_1114_907)">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.95591 14.3476H18.8963L18.8786 10.7603H3.93823L3.95591 14.3476Z" fill="#F24141"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M3.99119 21.5222H18.9316L18.9139 17.9348H3.97351L3.99119 21.5222Z" fill="#F24141"/>
              </g>
            </g>
            <path fillRule="evenodd" clipRule="evenodd" d="M11.4054 22.9309L10.0057 21.6765L9.98313 21.6563C8.41729 20.2585 7.05633 19.0436 6.09422 17.8849C5.11956 16.711 4.47205 15.5001 4.47205 14.1012C4.47205 11.7922 6.31782 10 8.6261 10C9.35569 10 10.0234 10.2785 10.5728 10.6281C10.874 10.8197 11.1535 11.0414 11.4054 11.2723C11.6574 11.0414 11.9369 10.8197 12.2381 10.6281C12.7875 10.2785 13.4552 10 14.1848 10C16.493 10 18.3388 11.7922 18.3388 14.1012C18.3388 15.5001 17.6913 16.711 16.7166 17.8849C15.7545 19.0436 14.3936 20.2585 12.8277 21.6563L12.8063 21.6755L11.4054 22.9309ZM12.301 21.1117C15.4818 18.2722 17.5817 16.3934 17.5817 14.1012C17.5817 12.2224 16.0871 10.7571 14.1848 10.7571C13.3631 10.7571 12.5666 11.2402 11.9363 11.8128C11.7422 11.9891 11.5639 12.1739 11.4054 12.3556C11.247 12.1739 11.0686 11.9891 10.8746 11.8128C10.2442 11.2402 9.4478 10.7571 8.6261 10.7571C6.7238 10.7571 5.22914 12.2224 5.22914 14.1012C5.22914 16.3934 7.32908 18.2722 10.5099 21.1117L11.4054 21.9143L12.301 21.1117Z" fill="#252323"/>
            <path d="M11.9213 11.8919C11.9213 11.6346 11.7128 11.4261 11.4555 11.4261C11.1983 11.4261 10.9897 11.6346 10.9897 11.8919C10.9897 12.1491 11.1983 12.3577 11.4555 12.3577C11.7128 12.3577 11.9213 12.1491 11.9213 11.8919Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.4402 11.8917C12.4402 11.3478 11.9993 10.9069 11.4555 10.9069C10.9116 10.9069 10.4707 11.3478 10.4707 11.8917C10.4707 12.4356 10.9116 12.8765 11.4555 12.8765C11.9993 12.8765 12.4402 12.4356 12.4402 11.8917ZM11.4555 11.4259C11.7127 11.4259 11.9213 11.6345 11.9213 11.8917C11.9213 12.1489 11.7127 12.3575 11.4555 12.3575C11.1982 12.3575 10.9897 12.1489 10.9897 11.8917C10.9897 11.6345 11.1982 11.4259 11.4555 11.4259Z" fill="#252323"/>
            <path d="M18.34 14.3142C18.34 14.0292 18.1091 13.7983 17.8241 13.7983C17.5392 13.7983 17.3082 14.0292 17.3082 14.3142C17.3082 14.5991 17.5392 14.8301 17.8241 14.8301C18.1091 14.8301 18.34 14.5991 18.34 14.3142Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M18.9149 14.3143C18.9149 13.7119 18.4265 13.2235 17.8241 13.2235C17.2217 13.2235 16.7334 13.7119 16.7334 14.3143C16.7334 14.9167 17.2217 15.405 17.8241 15.405C18.4265 15.405 18.9149 14.9167 18.9149 14.3143ZM17.8241 13.7984C18.1091 13.7984 18.34 14.0294 18.34 14.3143C18.34 14.5992 18.1091 14.8302 17.8241 14.8302C17.5392 14.8302 17.3082 14.5992 17.3082 14.3143C17.3082 14.0294 17.5392 13.7984 17.8241 13.7984Z" fill="#252323"/>
            <path d="M5.38435 14.3142C5.38435 14.0292 5.15337 13.7983 4.86845 13.7983C4.58352 13.7983 4.35254 14.0292 4.35254 14.3142C4.35254 14.5991 4.58352 14.8301 4.86845 14.8301C5.15337 14.8301 5.38435 14.5991 5.38435 14.3142Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M5.9592 14.3143C5.9592 13.7119 5.47085 13.2235 4.86845 13.2235C4.26605 13.2235 3.77771 13.7119 3.77771 14.3143C3.77771 14.9167 4.26605 15.405 4.86845 15.405C5.47085 15.405 5.9592 14.9167 5.9592 14.3143ZM4.86845 13.7984C5.15338 13.7984 5.38436 14.0294 5.38436 14.3143C5.38436 14.5992 5.15338 14.8302 4.86845 14.8302C4.58353 14.8302 4.35255 14.5992 4.35255 14.3143C4.35255 14.0294 4.58353 13.7984 4.86845 13.7984Z" fill="#252323"/>
            <path d="M11.8295 22.1634C11.8295 21.8986 11.6148 21.6838 11.3499 21.6838C11.085 21.6838 10.8702 21.8986 10.8702 22.1634C10.8702 22.4283 11.085 22.6431 11.3499 22.6431C11.6148 22.6431 11.8295 22.4283 11.8295 22.1634Z" fill="#FEFEFE"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.3639 22.1632C12.3639 21.6032 11.9099 21.1492 11.3498 21.1492C10.7898 21.1492 10.3358 21.6032 10.3358 22.1632C10.3358 22.7232 10.7898 23.1772 11.3498 23.1772C11.9099 23.1772 12.3639 22.7232 12.3639 22.1632ZM11.3498 21.6836C11.6147 21.6836 11.8295 21.8983 11.8295 22.1632C11.8295 22.4281 11.6147 22.6428 11.3498 22.6428C11.085 22.6428 10.8702 22.4281 10.8702 22.1632C10.8702 21.8983 11.085 21.6836 11.3498 21.6836Z" fill="#252323"/>
          </svg>
        </div>
      </div>

      <p className="text-neutral-50">
        Leads of <a href="https://friends.figma.com/" target="_blank" className="font-semibold underline">Friends of Figma</a> / Buenos Aires & Valencia
      </p>
    </div>
  )
}